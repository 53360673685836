
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="bottomTaskType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">账单列表</div>
          <!-- <div v-if="bottomTaskType!==1" :class="bottomTaskType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">订单列表</div> -->
        </div>

        <component :is="currentComponent + 'Component'" ref="bottom"  />
    </div>
</template>

<script>
export default {
  components: {
    listComponent: (resolve) => {
      require(['@/components/openbill/BillList'], resolve)
    }
  },
  data () {
    return {
      bottomTaskType: 1,
      currentComponent: 'list'
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.bottomTaskType = val
      if (val === 1) {
        this.currentComponent = 'list'
      } else if (val === 2) {
        this.currentComponent = 'detail'
      }
    }

  },
  computed: {
    beginUpdate () {
      return this.$store.state.openmarketbill.beginUpdate
    }
  },
  watch: {
    beginUpdate () {
    }
  }
}
</script>
